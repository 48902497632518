<template>
    <div>
        <CCard class="p-4"> 
            <CRow><CButton size="sm" class="btn btn-link back-btn"
				@click="backToTable()"><i class="fa fa-arrow-left"></i> Back</CButton>
			</CRow>
			<div class="pl-3 pr-3 pb-3"> 
			<CCard class="p-4">

				<CRow class="">
	        		<CCol lg="12">
	        			<h5>Incident Details</h5> <hr>
	        		</CCol>
	        		
	        	</CRow>

				<CRow class="">
	        		<CCol lg="12">
	        			<h3>
							#{{dataParams.incident_id}}
						</h3>
	        		</CCol>
	        		
	        	</CRow>
        		<CRow class="">
					
	        		<CCol lg="3">
	        			<label style="font-weight: 600;"> Class/Asset Type </label>
	        		</CCol>
	        		<CCol lg="8" class="text-uppercase">
	        			{{dataParams.class_type}}
	        		</CCol>
	        	</CRow>
	        	<CRow class="" v-if="dataParams.class_type == 'vehicle'">
	        		<CCol lg="3">
	        			<label style="font-weight: 600;"> Plate No. </label>
	        		</CCol>
	        		<CCol lg="9">
	        			<label>{{dataParams.vehicle.plate_no}}</label>
	        		</CCol>
	        	</CRow>
	        	<CRow class="" v-if="dataParams.class_type == 'equipment'">
	        		<CCol lg="3">
	        			<label style="font-weight: 600;"> Equipment Asset No. </label>
	        		</CCol>
	        		<CCol lg="9">
	        			{{dataParams.equipment.asset_no}}
	        		</CCol>
	        	</CRow>
				<!-- replace asset no to person involved -->
	        	<CRow class="">
	        		<CCol lg="3">
	        			<label style="font-weight: 600;"> Person Involved </label>
	        		</CCol>
	        		<CCol lg="9">
	        			{{dataParams.persons_involved == "" || dataParams.persons_involved == null ? "N/A" : dataParams.persons_involved}}
	        		</CCol>
	        	</CRow>
	        	<CRow class="">
	        		<CCol lg="3">
	        			<label style="font-weight: 600;"> Location of Incident </label>
	        		</CCol>
	        		<CCol lg="9">
	        			{{dataParams.current_location.setting_name}}
	        		</CCol>
	        	</CRow>
	        	<CRow class="">
	        		<CCol lg="3">
	        			<label style="font-weight: 600;"> Type</label>
	        		</CCol>
	        		<CCol lg="9">
	        			{{dataParams.type == "" ? "-" : dataParams.type}}
	        		</CCol>
	        	</CRow>
	        	
	        	<CRow class="">
	        		<CCol lg="3">
	        			<label style="font-weight: 600;"> Incident Date & Time </label>
	        		</CCol>
	        		<CCol lg="9">
						{{dataParams.incident_date? moment.unix(dataParams.incident_date).format('MMMM DD, YYYY hh:mm A') : "-"}}
	        			<!-- {{dataParams.incident_date == "" ? "N/A" : dataParams.incident_date_label}} -->
	        		</CCol>
	        	</CRow>
	        	<CRow class="">
	        		<CCol lg="3">
	        			<label style="font-weight: 600;"> Reported By </label>
	        		</CCol>
	        		<CCol lg="9">
	        			{{dataParams.report_by == "" || dataParams.report_by == null ? "-" : dataParams.report_by}}
	        		</CCol>
	        	</CRow>
	        	<CRow class="">
	        		<CCol lg="3">
	        			<label style="font-weight: 600;"> Description </label>
	        		</CCol>
	        		<CCol lg="9">
	        			{{dataParams.description == "" || dataParams.description == null ? "-" : dataParams.description}}
	        		</CCol>
	        	</CRow>
	        	<CRow class="">
	        		<CCol lg="3">
	        			<label style="font-weight: 600;"> Remarks </label>
	        		</CCol>
	        		<CCol lg="9">
	        			{{dataParams.remarks == "" || dataParams.remarks == null ? "-" : dataParams.remarks}}
	        		</CCol>
	        	</CRow>
				<CRow class="">
	        		<CCol lg="3">
	        			<label style="font-weight: 600;"> Status </label>
	        		</CCol>
	        		<CCol lg="9"  style="text-transform: uppercase;">
	        			{{dataParams.status}}
	        		</CCol>
	        	</CRow>
			</CCard>
        	</div>
        </CCard>
    </div>
  
	        	
		
</template>
<script>
import config from '../config.js';
import axios from '../axios';
import moment from 'moment';
export default {
	mounted(){  
		if(this.$route.params.id != undefined){
			this.editMode = true;
		} 
		if(this.$route.params.id){
			this.getData();
		} 

	},
	data(){
		return{
			moment,
			config,
			options:'',
			title:'',
			isLoading: false,
			formModal: false,
			showViewModal: false,
			dataListAll: [],
			dataList: [],
			dataListInactive: [],
			current_tab: "All",
			count: 0,
			depotName: "",
			depot_id: JSON.parse(window.localStorage.getItem('user_data')).depot_id,
			dataParams: {
				name: "",
				contact_no: "",
				email: "",
				birthday: "",
				home_address: "",
				gender: "",
				personnel_type_name: "",
				employment_type: "direct_hire",
				vendor_id: "",
				employee_id: "",
				depot_id: JSON.parse(window.localStorage.getItem('user_data')).depot_id != null ? JSON.parse(window.localStorage.getItem('user_data')).depot_id : "",
				date_started: "",
				date_seperation: "",
				philhealth_no: "",
				sss_no: "",
				status: "active",
				remarks: "",
				emergency_name: "",
				emergency_contact_no: "",
				bank_account: "",
				daily_rate: "",
				tin_no: "",
				pag_ibig_no: "",
				reason_of_separation: "",

			},
			editMode: false,
			selectedRow: {},
			showCollapse: false,

			setting_type : ''
		}
	},
	name: 'Tables',
	components: { },
	methods: { 
		 backToTable(){
	    	this.$router.push('/main/incident')
	    },
    // watch: {
	// 	'dataParams.name' : function(val) {
	// 		this.dataParams.name = val.toUpperCase()
	// 	}
	// },


		getData(){
			this.$showLoading(true)
			axios.get(config.api_path+"/incident/"+this.$route.params.id)
			.then(response=>{
				this.$showLoading(false)
				
				this.dataParams = response.data.data
			})
			.catch(err => {
			this.$showLoading(false)
		}) 
    },


    



	  

 	}
}
</script>
